export const MintDate = () => {
    return <div className="mint-date _2 wf-section">
        <div className="mint-div _2">
            <div className="container-10 w-container">
                <h1 className="mint-header"><strong className="bold-text-26">MINT DATE:</strong><strong
                    className="bold-text-25">‍</strong>
                </h1>
                <p className="mint-para head">Coming soon...</p>
                <p className="mint-para _2">protect yourself and the community from scammers. report fake
                    collections.
                </p>
            </div>
        </div>
    </div>
}
