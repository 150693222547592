import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  body .MuiDialog-container .MuiPaper-root {
    width: 480px;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 20px;
    background-color: #2c2c2c;
    box-shadow: 4px 4px 13px 0 #000;
  }
  body .MuiTypography-root {
    margin-top: 10px;
    font-family: Komtxtkb, sans-serif;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  body .MuiIconButton-root {
    padding-left: 185px;
  }
  body .MuiList-root {
    margin-bottom: 0px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 20px;
    background-color: #2c2c2c;
  }
  body .MuiList-root li {
    display: flex;
    margin-bottom: 10px;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12px;
    transition: background-color 200ms ease, opacity 200ms ease;
    color: #2c2c2c;
    text-decoration: none;
    margin-top: 1px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  body .MuiListItem-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10 px;
    padding-top: 3 px;
    padding-bottom: 3 px;
    padding-left: 10 px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12 px;
    background-color: #1f1f1f;
    -webkit-transition: background-color 200ms ease, opacity 200ms ease;
    transition: background-color 200ms ease, opacity 200ms ease;
    color: #2c2c2c;
    text-decoration: none;
  }
  body .MuiTypography-root .MuiIconButton-root .MuiSvgIcon-root {
    color: #d4ff52;
  }
  body .MuiPaper-root .MuiDialogContent-root .MuiList-root,
  body .MuiDialogContent-root .MuiCollapse-root .MuiList-root.MuiList-padding,
  body .MuiPaper-root .MuiDialogTitle-root {
    background-color: transparent;
  }
  body .MuiPaper-root .MuiDialogContent-root .MuiListItem-root {
    background-color: #1f1f1f;
    border-radius: inherit;
  }
  body .MuiListItem-root .MuiButton-label{
    margin-top: 0px;
    margin-left: 20px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
  }
  body .MuiButton-endIcon img{
    width: 50px;
    height: 50px;
  }
  body .MuiButton-root:after{
    background-color: transparent;
  }
  body .MuiDialogContent-root .MuiListItem-root .MuiButton-root:hover, body .MuiDialogContent-root .MuiListItem-root .MuiButton-root {
    border-radius: inherit;
  }
`
ReactDOM.render(
  <React.StrictMode>
      <GlobalStyle />
          <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
