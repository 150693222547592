import Red_Snake_Bg_Chop from '../assets/images/Red_Snake_Bg_Chop.png'
import Discord_Icon_Chopchop from '../assets/images/Discord_Icon_Chopchop.png'
import Join_discord_front from '../assets/images/Join_discord_front.png'
import Chop_Chop_Bubble from '../assets/images/Chop_Chop_Bubble.png'

export const JoinDiscordMobile = () => {
    return <div className="section-mv wf-section">
        <div className="circle-2 blue">
            <img src={Red_Snake_Bg_Chop} width="452" sizes="100vw" alt="" className="image-13" />
        </div>
        <div className="circle-2" />
        <img src={Discord_Icon_Chopchop} width="184" sizes="100vw" alt="" className="dog-2 _2" />
        <img src={Chop_Chop_Bubble} width="216" alt="" className="dog-2" />
        <img src={Join_discord_front} width="227" sizes="100vw" alt="" className="jump-3" />
        <div className="actions-2">
            <h1 className="heading-5">Join Our Discord</h1>
            <p className="paragraph text white link-secondary">Join our discord community to stay up to
                date <br />with the latest news, roadmap &amp; faq</p>
            <a href="https://discord.gg/Fu5zxU6Y8X" target="_blank"
               className="link-secondary button-3 chop w-button" rel="noreferrer">Join Discord</a>
        </div>
    </div>
}
