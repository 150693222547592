export const Premise = () => {
    return <div className="section-premise wf-section">
            <div className="w-container">
                <div className="div-block-8">
                    <h1 className="heading sec1">THE <br />‍<strong className="bold-text-2">PREMISE</strong></h1>
                    <p className="subheader-1 down">in the year 2056...</p>
                    <div className="w-row">
                        <div className="w-col w-col-6">
                            <p className="paragraph-4 premise">The virus has mutated for its 231st time and it&#x27;s
                                complications started resembling the decaying flesh zombie syndrome...<br /><br />Onset
                                    zombie symptoms came all of a sudden, causing riots and panic in the
                                    streets.<br />‍<br />People affected by the virus started having Zombie like features,
                                        while their intellectual capacity has diminished significantly. They started
                                        getting driven predominantly by the desire to consume human flesh. <br /><br />They
                                            were still functional, keeping some of the common human traits and
                                            characteristics, trying to keep on living their new lives while having to
                                            deal with sudden outbreaks and primal urges whenever they would sniff out a
                                            normie.<br />‍<br />
                            </p>
                        </div>
                        <div className="w-col w-col-6">
                            <p className="paragraph-4 premise">Many people managed to get away and find refuge in the Area
                                51 that was shut down after the last person managed to get there. It became the only
                                safe area clear of the infection. The remainder of the people that didn&#x27;t manage to
                                hide were left out in the world to fend for themselves. <br /><br />The conditions in the
                                    outside world became miserable and their only solitude is the metaverse that has
                                    already been omnipresent in the world prior to the virus.<br /><br />All three groups
                                        (including the zombies) have the ability of using the metaverse. Zombies using
                                        it mostly to sharpen their cognitive capacity and trying to trick both normies
                                        on the outside and in the sanctuary into revealing their hideouts and luring
                                        them into traps.
                            </p>
                        </div>
                    </div>
                    <p className="paragraph-4 cent">
                        <strong className="subheader-1">The story begins here. <br /></strong><br />To
                        participate in its creation visit us at&nbsp;
                        <a href="https://discord.gg/Fu5zxU6Y8X"
                            target="_blank"
                            className="a" rel="noreferrer">
                            Discord
                        </a>  or
                        <a href="https://twitter.com/ZombVerseNFT" className="a">
                            Twitter
                        </a>
                    </p>
                </div>
            </div>
        </div>
}
