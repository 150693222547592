import Logo_inner from '../assets/images/Logo_inner.png'
import Logo_Outer from '../assets/images/Logo_Outer.png'
import Discord_Mini_Header from '../assets/images/Discord_Mini_Header.svg'
import Twitter_Mini_Header from '../assets/images/Twitter_Mini_Header.svg'
import IG_Mini_Header from '../assets/images/IG_Mini_Header.svg'
import styled from "styled-components";
import {Header} from "../Header";
import {Fade, Modal, Box} from "@material-ui/core";
import {GatewayProvider} from "@civic/solana-gateway-react";
import {PublicKey} from "@solana/web3.js";
import {
    awaitTransactionSignatureConfirmation,
    CANDY_MACHINE_PROGRAM, CandyMachineAccount,
    getCandyMachineState,
    mintOneToken
} from "../candy-machine";
import {MintButton} from "../MintButton";
import {useWallet} from "@solana/wallet-adapter-react";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as anchor from "@project-serum/anchor";
import Alert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";
import {AlertState} from "../utils";
import {WalletDialogButton} from "@solana/wallet-adapter-material-ui";

const ConnectButton = styled(WalletDialogButton)`
  margin: 0px 34px 0px 51px;
  padding: 10px 31px 11px;
  align-self: center;
  border-style: solid;
  border-width: 1.5px;
  border-color: #000;
  border-radius: 50px;
  background-color: #000;
  box-shadow: none;
  transition: opacity 200ms ease, background-color 200ms ease, transform 200ms ease, border-color 200ms ease, color 200ms ease;
  font-family: Komtxtk, sans-serif;
  color: #fff;
  font-size: 17px;
  font-weight: 400;

  &:hover {
    border-style: solid;
    border-color: #c5ec38;
    background-color: #c5ec38;
    box-shadow: none;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    color: #000;
  }
`;
const MintContainer = styled.div``;
export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    txTimeout: number;
    rpcHost: string;
}

export const TopHeader = (props: HomeProps) => {
    const wallet = useWallet();
    const [isUserMinting, setIsUserMinting] = useState(false);
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    const rpcUrl = props.rpcHost;
    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }
        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);
    const refreshCandyMachineState = useCallback(async () => {
        if (!anchorWallet) {
            return;
        }

        if (props.candyMachineId) {
            try {
                const cndy = await getCandyMachineState(
                    anchorWallet,
                    props.candyMachineId,
                    props.connection,
                );
                setCandyMachine(cndy);
            } catch (e) {
                console.log('There was a problem fetching Candy Machine state');
                console.log(e);
            }
        }
    }, [anchorWallet, props.candyMachineId, props.connection]);
    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
        refreshCandyMachineState,
    ]);
    const onMint = async () => {
        try {
            setIsUserMinting(true);
            document.getElementById('#identity')?.click();
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                const mintTxId = (
                    await mintOneToken(candyMachine, wallet.publicKey)
                )[0];

                let status: any = {err: true};
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        true,
                    );
                }

                if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            });
        } finally {
            setIsUserMinting(false);
        }
    };
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: '',
        severity: undefined,
    });
    const style = {
        position: 'absolute',
        padding: '0px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#2c2c2c',
        borderRadius: '20px',
        boxShadow: '4px 4px 13px 0 #000'
    };
    const handleClose = () => wallet.disconnect();
    return <div>
        <div
            data-animation="default"
            data-collapse="medium"
            data-duration="400"
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="navbar w-nav"
        >
            <div className="container-4 w-container">
                <div className="brand w-nav-brand">
                    <img src={Logo_inner}
                         loading="lazy"
                         width="71"
                         alt=""
                         className="image-3"
                    />
                    <img src={Logo_Outer}
                         loading="lazy"
                         width="135"
                         data-w-id="9e0a3942-5e60-c445-e2ce-54c5e3393953"
                         alt="Logo"
                         className="image2"
                    />
                </div>
                <nav role="navigation" className="nav-menu w-nav-menu">
                    <a
                        href="https://mint.zombieversecomics.com"
                        target="_self"
                        rel="noreferrer"
                        className="button nav w-button"
                    >
                        MINT NOW
                    </a>
                    <a
                        href="https://discord.gg/Fu5zxU6Y8X"
                        target="_blank"
                        rel="noreferrer"
                        className="button social w-inline-block"
                    >
                        <img src={Discord_Mini_Header}
                             loading="lazy"
                             width="23"
                             alt="Visit Our Discord"
                             className="image-7"
                        />
                    </a>
                    <a
                        href="https://twitter.com/ZombVerseNFT"
                        target="_blank"
                        rel="noreferrer"
                        className="button social w-inline-block"
                    >
                        <img src={Twitter_Mini_Header}
                             loading="lazy"
                             width="23"
                             alt="Visit Our Twitter"
                             className="image-7"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/zombieversecomics/"
                        target="_blank"
                        rel="noreferrer"
                        className="button social w-inline-block"
                    >
                        <img src={IG_Mini_Header}
                             loading="lazy"
                             width="23"
                             alt="Visit Our Instagram"
                             className="image-7"
                        />
                    </a>
                </nav>
            </div>
        </div>
        <div className="section wf-section">
            <div className="hero-down w-container"/>
            <div className="div-block-9">
				<a
                    href="https://discord.gg/Fu5zxU6Y8X"
                    target="_blank"
                    rel="noreferrer"
                    className="button social w-inline-block"
                >
                    <img src={Discord_Mini_Header}
                         loading="lazy"
                         width="23"
                         alt="Visit Our Discord"
                         className="image-7"
                    />
                </a>
                <a
                    href="https://twitter.com/ZombVerseNFT"
                    target="_blank"
                    rel="noreferrer"
                    className="button social w-inline-block"
                >
                    <img src={Twitter_Mini_Header}
                         loading="lazy"
                         width="23"
                         alt="Visit Our Twitter"
                         className="image-7"
                    />
                </a>
                <a
                    href="https://www.instagram.com/zombieversecomics/"
                    target="_blank"
                    rel="noreferrer"
                    className="button social w-inline-block"
                >
                    <img src={IG_Mini_Header}
                         loading="lazy"
                         width="23"
                         alt="Visit Our Instagram"
                         className="image-7"
                    />
                </a>
            </div>
        </div>
        <Snackbar
            open={alertState.open}
            autoHideDuration={6000}
            onClose={() => setAlertState({...alertState, open: false})}
        >
            <Alert
                onClose={() => setAlertState({...alertState, open: false})}
                severity={alertState.severity}
            >
                {alertState.message}
            </Alert>
        </Snackbar>
    </div>
}
