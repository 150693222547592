import RoadmapStep1 from '../assets/images/Asset-82x-8.png'
import RoadmapStep2 from '../assets/images/Asset-72x-8.png'
import RoadmapStep3 from '../assets/images/Asset-62x-8.png'
import RoadmapStep4 from '../assets/images/Asset-52x-8.png'
import RoadmapStep5 from '../assets/images/Asset-42x-8.png'
import RoadmapStep1Picture from '../assets/images/slider_4.png'
import RoadmapStep2Picture from '../assets/images/slider_7.png'
import RoadmapStep3Picture from '../assets/images/slider_2.png'
import RoadmapStep4Picture from '../assets/images/slider_1.png'
import RoadmapStep5Picture from '../assets/images/slider_3.png'
import RoadmapStep1Picture500 from '../assets/images/slider_4-p-500.png'
import RoadmapStep2Picture500 from '../assets/images/slider_7-p-500.png'
import RoadmapStep3Picture500 from '../assets/images/slider_2-p-500.png'
import RoadmapStep4Picture500 from '../assets/images/slider_1-p-500.png'
import RoadmapStep5Picture500 from '../assets/images/slider_3-p-500.png'
import RoadmapStep1Picture800 from '../assets/images/slider_4-p-800.png'
import RoadmapStep2Picture800 from '../assets/images/slider_7-p-800.png'
import RoadmapStep3Picture800 from '../assets/images/slider_2-p-800.png'
import RoadmapStep4Picture800 from '../assets/images/slider_1-p-800.png'
import RoadmapStep5Picture800 from '../assets/images/slider_3-p-800.png'

export const Roadmap = () => {
    return <div id="How-It-works" data-w-id="f0e29e23-ba52-9455-82a3-575b6ce4f0a7"
                className="section-12 bcg wf-section">
        <div className="container-11">
            <div className="title-wrap percent">
                <h1 className="heading hero">
                    <br/>the<br/>‍<strong className="bold-text-24">Roadmap</strong>
                </h1>
            </div>
            <div className="w-layout-grid container-11 small flex">
                <div data-w-id="f0e29e23-ba52-9455-82a3-575b6ce4f0af" className="timeline-wrapper">
                    <div className="grey-line">
                        <div className="white-line" />
                    </div>
                </div>
                <div id="w-node-f0e29e23-ba52-9455-82a3-575b6ce4f0b2-4e1a50fe" className="process-wrapper">
                    <div className="step-wrapper margin-l">
                        <div className="step">
                            <div className="black-line" />
                            <img src={RoadmapStep1}
                                 loading="lazy"
                                 width="58"
                                 alt=""
                                 className="step-1-number"
                            />
                        </div>
                        <div className="step-1-info-wrapper">
                            <div className="process-image">
                                <img src={RoadmapStep1} loading="lazy" width="976"
                                    sizes="(max-width: 479px) 88vw, (max-width: 767px) 86vw, 240px"
                                    alt=""
                                    srcSet={`${RoadmapStep1Picture500} 500w, ${RoadmapStep1Picture800} 800w, ${RoadmapStep1Picture} 1000w`}
                                    className="image-17"
                                />
                            </div>
                            <div className="step-info">
                                <h4 className="step-heading margin-xs">10% Sold</h4>
                                <p className="paragraph-m text-white margin-m">Basics</p>
                                <ul role="list" className="list-q _2">
                                    <li>
                                        <p className="paragraph-2 q"><strong className="bold-text-33">50</strong> sol
                                            raffled to holders</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-wrapper margin-l">
                        <div className="step">
                            <img src={RoadmapStep2}
                                 loading="lazy"
                                 width="58" alt=""
                                 className="step-1-number"
                            />
                            <div className="black-line" />
                        </div>
                        <div className="step-2-info-wrapper">
                            <div className="process-image">
                                <img src={RoadmapStep2} loading="lazy"
                                    sizes="(max-width: 479px) 83vw, (max-width: 767px) 86vw, 240px"
                                    srcSet={`${RoadmapStep2Picture500} 500w, ${RoadmapStep2Picture800} 800w, ${RoadmapStep2Picture} 1000w`}
                                    alt="" className="image-18"
                                />
                            </div>
                            <div className="step-info">
                                <h4 className="step-heading margin-xs">25% Sold</h4>
                                <p className="paragraph-m text-white margin-m">Community NFT bombing</p>
                                <ul role="list" className="list-q _2">
									<li>
                                        <p className="paragraph-2 q">Comic Book #1 unlocked </p>
                                    </li>
                                    <li>
                                        <p className="paragraph-2 q"><strong className="bold-text-35">20</strong> zombie
                                            NFTs are air-dropped to random holders </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-wrapper margin-l">
                        <div className="step">
                            <img src={RoadmapStep3}
                                loading="lazy" width="58" alt=""
                                className="step-1-number"
                            />
                            <div className="black-line" />
                        </div>
                        <div className="step-3-info-wrapper">
                            <div className="process-image">
                                <img src={RoadmapStep3} loading="lazy" width="385"
                                    sizes="(max-width: 479px) 88vw, (max-width: 767px) 385px, 240px"
                                    alt=""
                                    srcSet={`${RoadmapStep3Picture500} 500w, ${RoadmapStep3Picture800} 800w, ${RoadmapStep3Picture} 1000w`}
                                    className="image-19" />
                            </div>
                            <div className="step-info">
                                <h4 className="step-heading margin-xs">50% Sold</h4>
                                <p className="paragraph-m text-white margin-m">Utilization</p>
                                <ul role="list" className="list-q _2">
                                    <li>
                                        <p className="paragraph-2 q">Write to earn utility is unlocked </p>
                                    </li>
                                    <li>
                                        <p className="paragraph-2 q"><strong className="bold-text-36">50</strong> sol
                                            raffled to holders</p>
                                    </li>
                                    <li>
                                        <p className="paragraph-2 q">Comic Book #2 unlocked </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-wrapper margin-l">
                        <div className="step">
                            <img src={RoadmapStep4}
                                loading="lazy" width="58" alt=""
                                className="step-1-number" />
                            <div className="black-line" />
                        </div>
                        <div className="step-4-info-wrapper">
                            <div className="process-image">
                                <img src={RoadmapStep4} loading="lazy"
                                    sizes="(max-width: 479px) 84vw, (max-width: 767px) 86vw, 240px"
                                    srcSet={`${RoadmapStep4Picture500} 500w, ${RoadmapStep4Picture800} 800w, ${RoadmapStep4Picture} 1000w`}
                                    alt="" className="image-20" />
                            </div>
                            <div className="step-info">
                                <h4 className="step-heading margin-xs">75% Sold</h4>
                                <p className="paragraph-m text-white margin-m">From ZERO to HERO</p>
                                <ul role="list" className="list-q _2">
                                    <li>
                                        <p className="paragraph-2 q">Special (HERO) collection is unlocked</p>
                                    </li>
                                    <li>
                                        <p className="paragraph-2 q">Comic Book #3 unlocked </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="step-wrapper">
                        <div className="step">
                            <img src={RoadmapStep5}
                                loading="lazy" width="58" alt=""
                                className="step-1-number" />
                            <div className="black-line" />
                        </div>
                        <div className="step-5-info-wrapper">
                            <div className="process-image">
                                <img src={RoadmapStep5} loading="lazy"
                                    sizes="(max-width: 479px) 88vw, (max-width: 767px) 86vw, 240px"
                                    srcSet={`${RoadmapStep5Picture500} 500w, ${RoadmapStep5Picture800} 800w, ${RoadmapStep5Picture} 1000w`}
                                    alt="" className="image-21" />
                            </div>
                            <div className="step-info">
                                <h4 className="step-heading margin-xs">100% Sold</h4>
                                <p className="paragraph-m text-white margin-m">Releasing it to the world...</p>
                                <ul role="list" className="list-q _2">
                                    <li>
                                        <p className="paragraph-2 q"><strong className="bold-text-40">100</strong> sol
                                            distributed</p>
                                    </li>
                                    <li>
                                        <p className="paragraph-2 q">Comic Book #4 unlocked </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
