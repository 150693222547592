import Slider_1 from '../assets/images/Slider_1.png'
import Slider_1_p_500 from '../assets/images/Slider_1_p_500.png'
import Slider_2 from '../assets/images/Slider_2.png'
import Slider_2_p_500 from '../assets/images/Slider_2_p_500.png'
import Slider_3 from '../assets/images/Slider_3.png'
import Slider_3_p_500 from '../assets/images/Slider_3_p_500.png'
import Slider_4 from '../assets/images/Slider_4.png'
import Slider_4_p_500 from '../assets/images/Slider_4_p_500.png'
import Slider_5 from '../assets/images/Slider_5.png'
import Slider_5_p_500 from '../assets/images/Slider_5_p_500.png'
import Slider_6 from '../assets/images/Slider_6.png'
import Slider_6_p_500 from '../assets/images/Slider_6_p_500.png'
import Slider_7 from '../assets/images/Slider_7.png'
import Slider_7_p_500 from '../assets/images/Slider_7_p_500.png'
import Slider_8 from '../assets/images/Slider_8.png'
import Slider_8_p_500 from '../assets/images/Slider_8_p_500.png'
import Slider_9 from '../assets/images/Slider_9.png'
import Slider_9_p_500 from '../assets/images/Slider_9_p_500.png'
import Slider_10 from '../assets/images/Slider_10.png'
import Slider_10_p_500 from '../assets/images/Slider_10_p_500.png'

export const Slider = () => {
    return <div className="section-3 wf-section">
        <h1 className="heading free">150+ <strong className="bold-text-2">Unique Traits</strong></h1>
        <div className="slider-wrapper">
            <div className="items-holder">
                <div className="items">
                    <img src={Slider_1}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_1_p_500} 500w, ${Slider_1} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_2}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_2_p_500} 500w, ${Slider_2} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_3}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_3_p_500} 500w, ${Slider_3} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_4}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_4_p_500} 500w, ${Slider_4} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_6}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_6_p_500} 500w, ${Slider_6} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_7}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_7_p_500} 500w, ${Slider_7} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_8}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_8_p_500} 500w, ${Slider_8} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_5}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_5_p_500} 500w, ${Slider_5} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_9}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_9_p_500} 500w, ${Slider_9} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_10}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_10_p_500} 500w, ${Slider_10} 1000w`}
                         alt=""
                         className="item"
                    />
                </div>
                <div className="items">
                    <img src={Slider_1}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_1_p_500} 500w, ${Slider_1} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_2}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_2_p_500} 500w, ${Slider_2} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_3}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_3_p_500} 500w, ${Slider_3} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_4}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_4_p_500} 500w, ${Slider_4} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_6}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_6_p_500} 500w, ${Slider_6} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_7}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_7_p_500} 500w, ${Slider_7} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_8}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_8_p_500} 500w, ${Slider_8} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_5}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_5_p_500} 500w, ${Slider_5} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_9}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_9_p_500} 500w, ${Slider_9} 1000w`}
                         alt=""
                         className="item"
                    />
                    <img src={Slider_10}
                         loading="eager"
                         sizes="250px"
                         srcSet={`${Slider_10_p_500} 500w, ${Slider_10} 1000w`}
                         alt=""
                         className="item"
                    />
                </div>
            </div>
        </div>
        <div className="trait-cont _13 w-container">
            <div className="div-block-4 _2">
                <div className="title-wrap">
                    <p className="subheader-1 down">2222 generative zombie art pieces with 150+ unique traits. <br />
                        Each zombie is original, with its own color palette and creation.</p>
                </div>
            </div>
            <div className="paragraph _1">You are invited to earn the opportunity of owning an exclusive membership
                access to our community striving on making the first community based NFT comic book a reality,
                while earning benefits along the way based on levels of contributions. The more you contribute
                the higher you climb on our membership&#x27;s exclusivity ladder.<br/></div>
        </div>
        <div className="w-embed">
        </div>
    </div>
}
