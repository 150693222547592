import Plus from '../assets/images/Plus.svg'

export const Faq = () => {
  return <div className="section-5">
        <div className="container-6">
          <div className="title-wrap">
            <h1 className="heading-5">FAQ</h1>
            <div className="subheader-1">We've got you covered</div>
          </div>
          <div className="column-wrapper">
            <div className="column-5">
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h2 className="subheader"><strong className="bold-text-14">What is the total supply?</strong></h2>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">A total of 2,222 unique Zombie NFTs will be created.<br /></p>
                </div>
              </div>
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h3 className="subheader">"Write To Earn" concept?</h3>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">Community members will participate in creation of the finalized utility (comic book NFT) and as a result receive multiple venues of perks for secured for the owners. </p>
                </div>
              </div>
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h3 className="subheader"><strong className="bold-text-17">Will there be a pre-sale?</strong></h3>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">All the contributing members will be whitelisted for the mint pass that will guarantee future benefits.<br /></p>
                </div>
              </div>
            </div>
            <div className="column-5">
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h3 className="subheader"><strong className="subheader">When is the launch?<br /></strong></h3>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">Q4 2022<br /></p>
                </div>
              </div>
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h3 className="subheader"><strong className="bold-text-15">How much is minting an NFT?<br /></strong></h3>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">WL 1.25 SOL / Public 1.5 SOL<br /></p>
                </div>
              </div>
              <div className="faq-question-wrap">
                <div className="faq">
                  <div className="faq-circle"><img src={Plus} width={18} alt="" className="faq-icon noselect" /></div>
                  <h3 className="subheader"><strong className="bold-text-16">Have more questions?<br /></strong></h3>
                </div>
                <div className="faq-content">
                  <p className="paragraph-3">Join our <a href="https://discord.gg/Fu5zxU6Y8X" target="_blank" className="link" rel="noreferrer">Discord </a>to learn more.<br /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
