import ComicStep1 from '../assets/images/ComicStep1.png'
import ComicStep1_p_500 from '../assets/images/ComicStep1_p_500.png'
import ComicStep1_p_800 from '../assets/images/ComicStep1_p_800.png'
import ComicStep2 from '../assets/images/ComicStep2.png'
import ComicStep2_p_500 from '../assets/images/ComicStep2_p_500.png'
import ComicStep3 from '../assets/images/ComicStep3.png'
import ComicStep3_p_500 from '../assets/images/ComicStep3_p_500.png'
import ComicStep4 from '../assets/images/ComicStep4.png'
import ComicStep4_p_500 from '../assets/images/ComicStep4_p_500.png'
import ComicStep5 from '../assets/images/ComicStep5.png'
import ComicStep5_p_500 from '../assets/images/ComicStep5_p_500.png'

export const ComicBook = () => {
    return <div id="welcome" className="section-2 unique _3 wf-section">
            <div className="w-container">
                <div className="div-block-4 _2">
                    <div className="title-wrap">
                        <h1 className="heading hero"><br />How Does it<br /><strong className="bold-text-2">Work?</strong></h1>
                        <div className="paragraph _1"><strong className="subheader-1">It&#x27;s pretty simple... The main
                            community functions are participation by guiding the story line and writing dialogues for
                            the comic books and voting on election days. Both activities will be appropriately rewarded
                            based on participation.</strong><br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-8 _2 w-container">
                <div className="columns-8 w-row">
                    <div className="column-14 w-col w-col-9">
                        <div className="div-block-10">
                          <img src={ComicStep1}
                               loading="lazy" width="1047"
                               sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 73vw, 720px"
                               srcSet={`${ComicStep1_p_500} 500w, ${ComicStep1_p_800} 800w, ${ComicStep1_p_500} 814w`}
                               alt="" className="cb-image" />
                          </div>
                    </div>
                    <div className="column-17 w-col w-col-3">
                        <div>
                            <p className="paragraph bubbles _2 _3">‍<strong className="bold-text-4">Step 1: </strong>
                              <strong className="bold-text-19">Creation process starts now. Based on a premise, community is
                                presented with a challenge of writing the suggestions for their desired story
                                plot.
                              </strong><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="columns-7 w-row">
                    <div className="column-13 w-col w-col-6">
                        <div className="wdwq">
                          <img src={ComicStep2}
                               loading="lazy"
                               width="711"
                               sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 49vw, 480px"
                               srcSet={`${ComicStep2_p_500} 500w, ${ComicStep2} 814w`}
							   alt=""
                               className="cb-image" />
                          </div>
                    </div>
                    <div className="column-18 w-col w-col-6">
                        <div>
                            <p className="paragraph bubbles _2">‍<strong className="bold-text-4">Step 2: </strong>
                              <strong className="bold-text-20">Story suggestions are presented and the community is asked to vote on their favorite story continuation. Based on the selected story, we are starting the process of storyboard drawing.
                              </strong><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="columns-7 w-row">
                    <div className="column-12 w-col w-col-8">
                        <div>
                          <img src={ComicStep3} loading="lazy" width="701"
                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 65vw, 640px"
                            srcSet={`${ComicStep3_p_500} 500w, ${ComicStep3} 814w`}
							alt="" className="wdwq" />
                          </div>
                    </div>
                    <div className="column-19 w-col w-col-4">
                        <div>
                            <p className="paragraph bubbles _2 _4">‍<strong className="bold-text-4">Step 3: </strong>
                              <strong className="bold-text-21">Selection of storyboard dialogues starts via direct interactions with the artist to select the best, most awesome dialogue for a specific panel position.</strong><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="columns-9 w-row">
                    <div className="column-15 w-col w-col-7">
                        <div className="wdwq">
                          <img src={ComicStep4} loading="lazy" width="755"
                             sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 57vw, 560px"
                             srcSet={`${ComicStep4_p_500} 500w, ${ComicStep4} 814w`}
							 alt=""
                             className="cb-image" />
                        </div>
                    </div>
                    <div className="column-20 w-col w-col-5">
                        <div>
                            <p className="paragraph bubbles _2 _213">‍<strong className="bold-text-4">Step 4: </strong>
                              <strong className="bold-text-22">Dialogues are in, community winners selected and rewarded,
                                and work on the next storyboard cycle officially begins. Rinse and repeat until we get to the final
                                storyboard. At that moment, finalization process will take place (coloring, shading). </strong><br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="columns-7 w-row">
                    <div className="column-16 w-col w-col-8">
                        <div>
                          <img src={ComicStep5} loading="lazy" width="881"
                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 65vw, 640px"
                            srcSet={`${ComicStep5_p_500} 500w, ${ComicStep5} 814w`}
							alt="" className="cb-image" />
                        </div>
                    </div>
                    <div className="column-21 w-col w-col-4">
                        <div>
                            <p className="paragraph bubbles _2 _23432">‍<strong className="bold-text-4">Step 5: </strong>
                              <strong className="bold-text-23">Public mint date is announced when the comic book #1 is finalized and
                                ready to be released. All contributors that have been rewarded with pre-mint passes
                                will receive the NFT airdropped prior to public mint date. Keep your mint pass, it will
                                carry its own set of benefits in the future.</strong><br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}
