import SpecialtyAirdrops from '../assets/images/SpecialtyAirdrops.png'
import SpecialtyAirdrops_500 from '../assets/images/SpecialtyAirdrops_500.png'
import SpecialtyAirdrops_800 from '../assets/images/SpecialtyAirdrops_800.png'
import SpecialtyAirdrops_1080 from '../assets/images/SpecialtyAirdrops_1080.png'
import HeroCollection from '../assets/images/hero_collection.png'
import HeroCollection500 from '../assets/images/hero_collection-p-500.png'
import HeroCollection800 from '../assets/images/hero_collection-p-800.png'
import HeroCollection1080 from '../assets/images/hero_collection-p-1080.png'

export const Ownership = () => {
    return <div className="ownership wf-section">
            <div className="container-6">
                <div className="title-wrap">
                    <h1 className="heading hero"><br />Ownership<br />‍
                      <strong className="bold-text-2">Benefits</strong>
                    </h1>
                    <div className="paragraph _1"><strong className="subheader-1 loyalty">In order to earn the loyalty of our
                        community, we&#x27;ve decided that the first collection will drop <br/>only after the first comic
                            book is ready to be released.</strong><br/></div>
                </div>
                <div className="columns-6 benefits _2 w-row">
                    <div className="column-7 w-col w-col-6">
                        <div className="div-block-2 _3 benefits">
                          <img src={SpecialtyAirdrops}
                              loading="lazy"
                              width="150"
                              sizes="150px"
                              srcSet={`${SpecialtyAirdrops_500} 500w, ${SpecialtyAirdrops_800} 800w, ${SpecialtyAirdrops_1080} 1080w, ${SpecialtyAirdrops} 1110w`}
                              alt=""
                          />
                          <h1 className="heading-10">‍<strong className="bold-text-3">Specialty Airdrops</strong></h1>
                          <p className="paragraph-2 benefits">
                              Community comic book NFTs airdropped to holders. Also, a very limited collection &quot;Unique
                              Heroes&quot; NFT will be airdropped to community content MVC&#x27;s (Most Valuable Contributors)<br />
                          </p>
                        </div>
                    </div>
                    <div className="column-10 w-col w-col-6">
                        <div className="div-block-2 _3 benefits">
                          <img src={HeroCollection}
                            loading="lazy"
                            width="159"
                            sizes="(max-width: 479px) 71vw, 159px"
                            srcSet={`${HeroCollection500} 500w, ${HeroCollection800} 800w, ${HeroCollection1080} 1080w, ${HeroCollection} 1339w`}
                            alt=""
                          />
                          <h1 className="heading-11"><strong className="bold-text-3">Hero Collection</strong></h1>
                          <p className="paragraph-2 benefits">
                              Hero collection will be comprised of Zombies that were featured in the comic books. They
                              will be the subject of future character development and will be released with full creative
                              and commercial rights!<br />
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}


