import Logo from '../assets/images/Logo.png'
import DiscordIcon from '../assets/images/DiscordIcon.svg'
import InstagramIcon from '../assets/images/InstagramIcon.svg'
import TwitterIcon from '../assets/images/TwitterIcon.svg'

export const Footer = () => {
    return <footer id="footer" className="footer wf-section">
            <div className="w-container">
                <div className="footer-flex-container">
                    <div className="footer-logo-link">
                      <img src={Logo} alt="" className="footer-image"/>
                    </div>
                    <div className="div-block-7">
                        <a href="https://discord.gg/Fu5zxU6Y8X" target="_blank"
                           className="button social w-inline-block" rel="noreferrer">
                          <img
                              src={DiscordIcon}
                              loading="lazy"
                              width={23}
                              alt=""
                              className="image-7"
                          />
                        </a>
                        <a href="https://twitter.com/ZombVerseNFT" target="_blank"
                           className="button social w-inline-block" rel="noreferrer">
                          <img src={TwitterIcon}
                               loading="lazy"
                               width={23}
                               alt=""
                               className="image-7"
                          />
                        </a>
                        <a href="https://www.instagram.com/zombieversecomics/" target="_blank"
                           className="button social w-inline-block" rel="noreferrer">
                          <img src={InstagramIcon}
                               loading="lazy"
                               width={23}
                               alt=""
                               className="image-7"
                          />
                        </a>
                    </div>
                    <div className="text-block-2">Copyright © 2021 Zombieverse. All rights reserved.</div>
                </div>
            </div>
        </footer>
}
