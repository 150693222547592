import Team_zuka from '../assets/images/Team_zuka.png'
import Team_zuka_500 from '../assets/images/Team_zuka_500.png'
import Team_martinez from '../assets/images/Team_martinez.png'
import Team_martinez_500 from '../assets/images/Team_martinez_500.png'
import Team_droic from '../assets/images/Team_droic.png'
import Team_droic_500 from '../assets/images/Team_droic_500.png'

export const Team = () => {
    return <div className="section-5 team wf-section">
        <div className="container w-container">
            <div className="title-wrap">
                <h1 className="heading-5">The Team</h1>
                <div className="subheader-1">This is a project between three friends. We’re set out on creating
                    something new and meaningfull, cross-referencing our past, present and future while having a
                    blast at the same time. We’re hoping you guys will love this project and join us on this
                    little adventure.<br/>
                </div>
            </div>
            <div className="team-columns w-row">
                <div className="column-7 team w-col w-col-4">
                    <div className="div-block-2 team">
                        <ul className="w-list-unstyled">
                            <li><img src={Team_zuka} loading="lazy" width={238}
                                     sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 30vw, 238px"
                                     srcSet={`${Team_zuka_500} 500w, ${Team_zuka} 635w`}
                                     alt=""
                                     className="image-6"/>
                                <h1 className="heading-2 team">Zuka</h1>
                            </li>
                            <li>
                                <p className="paragraph team1">HEAD DEVELOPMENT</p>
                            </li>
                            <li>
                                <p className="paragraph text team">Travels to appear adventurous<br/></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="column-7 team w-col w-col-4">
                    <div className="div-block-2">
                        <ul className="w-list-unstyled">
                            <li><img src={Team_martinez} loading="lazy" width={238}
                                     sizes="(max-width: 479px) 100vw, (max-width: 767px) 50vw, (max-width: 991px) 30vw, 238px"
                                     srcSet={`${Team_martinez_500} 500w, ${Team_martinez} 635w`}
                                     alt=""
                                     className="image-6"/>
                                <h1 className="heading-2 team">Martinez</h1>
                            </li>
                            <li>
                                <p className="paragraph team1">ILLUSTRATION &amp; DESIGN</p>
                            </li>
                            <li>
                                <p className="paragraph text team">Smokes to aid intermittent fasting<br/></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="column-7 team w-col w-col-4">
                    <div className="div-block-2">
                        <ul className="w-list-unstyled">
                            <li><img src={Team_droic} loading="lazy" width={238}
                                     sizes="(max-width: 479px) 84vw, (max-width: 767px) 50vw, (max-width: 991px) 30vw, 238px"
                                     srcSet={`${Team_droic_500} 500w, ${Team_droic} 635w`}
                                     alt=""
                                     className="image-6"/>
                                <h1 className="heading-2 team">0xDraic</h1>
                            </li>
                            <li>
                                <p className="paragraph team1">BRAND &amp; PRODUCT</p>
                            </li>
                            <li>
                                <p className="paragraph text team">- Sweatpants are still pants<br/></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
