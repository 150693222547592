import './App.css';
import {useMemo} from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import {clusterApiUrl} from '@solana/web3.js';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';

import {ConnectionProvider, WalletProvider,} from '@solana/wallet-adapter-react';
import {WalletDialogProvider} from '@solana/wallet-adapter-material-ui';

import {createTheme, ThemeProvider} from '@material-ui/core';
import styled from "styled-components";

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(
        process.env.REACT_APP_CANDY_MACHINE_ID!,
    );
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost
  ? rpcHost
  : anchor.web3.clusterApiUrl('devnet'));

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
const txTimeoutInMilliseconds = 30000;
const WalletDialogProviderZomb = styled.div`
    & .MuiButtonBase-root {
    margin: 0px 34px 0px 51px;
    padding: 10px 31px 11px;
    align-self: center;
    border-style: solid;
    border-width: 1.5px;
    border-color: #000;
    border-radius: 50px;
    background-color: #000;
    box-shadow: none;
    transition: opacity 200ms ease, background-color 200ms ease, transform 200ms ease, border-color 200ms ease, color 200ms ease;
    font-family: Komtxtk, sans-serif;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    }

    & .MuiButtonBase-root:hover {
      border-style: solid;
      border-color: #c5ec38;
      background-color: #c5ec38;
      box-shadow: none;
      color: #000;
    }
`;
const App = () => {
const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
            <WalletDialogProviderZomb>
                <WalletDialogProvider>
                    <Home
                        candyMachineId={candyMachineId}
                        connection={connection}
                        startDate={startDateSeed}
                        txTimeout={txTimeoutInMilliseconds}
                        rpcHost={rpcHost}
                    />
                </WalletDialogProvider>
            </WalletDialogProviderZomb>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
