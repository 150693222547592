import * as anchor from '@project-serum/anchor';
import {Container} from '@material-ui/core';
import {TopHeader} from "./components/TopHeader";
import {Welcome} from "./components/Welcome";
import {ComicBook} from "./components/ComicBook";
import {Footer} from "./components/Footer";
import {JoinDiscord} from "./components/JoinDiscord";
import {JoinDiscordMobile} from "./components/JoinDiscordMobile";
import {Slider} from "./components/Slider";
import {Ownership} from "./components/Ownership";
import {Premise} from "./components/Premise";
import {Roadmap} from "./components/Roadmap";
import {Team} from "./components/Team";
import {Faq} from "./components/Faq";
import {MintDate} from "./components/MintDate";
import {Phases} from "./components/Phases";

export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    txTimeout: number;
    rpcHost: string;
}

const Home = (props: HomeProps) => {
    return (
        <Container disableGutters maxWidth={false}>
            <TopHeader
                candyMachineId={props.candyMachineId}
                connection={props.connection}
                startDate={props.startDate}
                txTimeout={props.txTimeout}
                rpcHost={props.rpcHost}
            />
            <Welcome />

            <div className="separator _1 wf-section" />

            <Slider />

            <MintDate />

            <Ownership />

            <Premise />

            <ComicBook />

            <Roadmap />

            <Phases />

            <JoinDiscordMobile />

            <JoinDiscord />

            <Team />

            <Faq />

            <div className="separator _2 wf-section" />
            <Footer />
        </Container>
    );
};

export default Home;
