import Art_project_front from '../assets/images/Art_project_front.png'
import Art_project_front_p_500 from '../assets/images/Art_project_front_p_500.png'

export const Welcome = () => {
    return <div id="welcome" className="section-2 unique wf-section">
        <div className="container hero w-container">
            <div className="div-block-4">
                <div className="title-wrap">
                    <h1 className="heading hero">
                        <br />Welcome to<br />
                        <strong className="bold-text-2">Zombieverse</strong>
                    </h1>
                    <div className="subheader-1">
                        Write to earn concept has landed...<br />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-8 zombieverse w-container">
            <div className="div-block">
                <img
                    src={Art_project_front}
                    loading="lazy"
                    width="450"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 50vw, (max-width: 991px) 47vw, 450px"
                    srcSet={`${Art_project_front} 500w, ${Art_project_front_p_500} 500w, ${Art_project_front} 635w`} alt=""
                    className="image-2 _1"
                />
            </div>
            <div className="div-block">
                <p className="paragraph _1 _2">
                    Zombieverse is a collection of 2222 zombies, generative art pieces with hundreds of attributes.
                    Each artwork is original, with its own color palette and creation.<br /><br />Each community member will
                    get an opportunity to participate in a &quot;write to earn&quot; utility concept, working with us to
                    produce the first NFT comic book series created by the community for the community and later on within
                    the launchpad. Participation will garner you an opportunity to get rewarded with SOL prizes and
                    free Mint Pass NFTs + airdrop of a finished comic book NFT at completion.<br /><br />Mint passes are
                    open for purchase from day one, and also rewarded based on community contributions, while we are
                    working as a towards the comic book completion and a public mint sale event.<br />
                </p>
            </div>
        </div>
    </div>
}


