import Red_Snake_Bg_Chop from '../assets/images/Red_Snake_Bg_Chop.png'
import Discord_Icon_Chopchop from '../assets/images/Discord_Icon_Chopchop.png'
import Join_discord_front from '../assets/images/Join_discord_front.png'
import Chop_Chop_Bubble from '../assets/images/Chop_Chop_Bubble.png'

export const JoinDiscord = () => {
    return <div className="mv-sec-2 wf-section">
        <div data-w-id="c87b7086-8ec7-c643-8cc8-8c3806dc6bfe"
             className="container-9 w-container"
             style={{willChange: 'transform',
                 transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                 transformStyle: 'preserve-3d'}}
        >
            <div className="circle-2 blue">
                <img src={Red_Snake_Bg_Chop} loading="lazy" width="452" sizes="100vw" alt="" className="image-13"/>
            </div>
            <div className="circle-2"/>
            <img src={Discord_Icon_Chopchop} width="184" sizes="100vw" alt="" className="dog-2 _2"/>
            <img src={Chop_Chop_Bubble} width="216" alt="" className="dog-2"/>
            <img src={Join_discord_front} width="227" sizes="100vw" alt="" className="jump-3"/>
            <div className="actions-2">
                <h1 className="heading-5">Join Our Discord</h1>
                <p className="paragraph text white link-secondary">Join our discord community to stay up to
                    date <br/>with the latest news, roadmap &amp; faq</p>
                <a href="https://discord.gg/Fu5zxU6Y8X" target="_blank"
                   className="link-secondary button-3 chop w-button" rel="noreferrer">Join Discord</a>
            </div>
        </div>
    </div>
}
