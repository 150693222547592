export const Phases = () => {
    return <div className="section-10 roadmap wf-section">
        <div className="container w-container">
            <div className="div-block-4">
                <div className="title-wrap _2">
                    <h1 className="heading hero">
                        <br/>the<br/><strong className="bold-text-24">Phases</strong>
                    </h1>
                    <div className="subheader-1">Phases of action for utilization of our collection and activites around
                        it. <br/>
                    </div>
                </div>
                <div className="phases-column qs w-row">
                    <div className="column-9 w-col w-col-6">
                        <div className="div-block-11 _3 q">
                            <h2 className="h2-2 road _2">
                                q4 <strong className="bold-text-30">2022</strong>
                            </h2>
                            <ul role="list" className="list-q">
                                <li>
                                    <p className="paragraph-2 q done">Official Website Launch<br/></p>
                                </li>
                                <li>
                                    <p className="paragraph-2 q done">Unique story submission system, Discord +
                                        Twitter <br/></p>
                                </li>
                                <li>
                                    <p className="paragraph-2 q done">Custom Storyboard Voting System<br/></p>
                                </li>
                                <li>
                                    <p className="paragraph-2 q done">Write To Earn Concept Testing<br/></p>
                                </li>
                                <li>
                                    <p className="paragraph-2 q done">Comic Book Pilot<br/></p>
                                </li>
								<li>
                                    <p className="paragraph-2 q">Completion of the first issue of Zombieverse Series</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="column-9 w-col w-col-6">
                        <div className="div-block-11 _3 q">
                            <h2 className="h2-2 road _2">
							q1 <strong className="bold-text-30">2023</strong><br/></h2>
                            <ul role="list" className="list-q">
                                <li>
                                    <p className="paragraph-2 q">Completion of the second issue of Zombieverse Series, following the story of the normies left out in the zombieverse</p>
                                </li>
                                <li>
                                    <p className="paragraph-2 q">Airdrop of the second comic book NFT to Zombieverse NFT holders</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="phases-column qs _2 w-row">
                    <div className="column-9 w-col w-col-6">
                        <div className="div-block-11 _3 q">
                            <h2 className="h2-2 road _2">
							q2 <strong className="bold-text-31">2023</strong></h2>
                            <ul role="list" className="list-q">
                                <li>
                                    <p className="paragraph-2 q">Completion of the third issue of the Zombieverse Series, following story of the normies that managed to escape into a sanctuary</p>
									<li>
                                    <p className="paragraph-2 q">Airdrop of the third comic book NFT to all Zombieverse NFT holders</p>
                                </li>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="column-9 w-col w-col-6">
                        <div className="div-block-11 _3 q">
                            <h2 className="h2-2 road _2">
							q3 <strong className="bold-text-30">2023</strong><br/></h2>
                            <ul role="list" className="list-q">
                                <li>
                                    <p className="paragraph-2 q">Release of the fourth and final comic book in the Zombieverse
                                        series, bringing all groups from previous books to a final showdown</p>
                                </li>
								 <li>
                                    <p className="paragraph-2 q">Airdrop of the fourth comic book NFT to all Zombieverse NFT holders</p>
                                </li>
								<li>
                                    <p className="paragraph-2 q">HERO collection drop, comprised of the comic book hero
                                        characters, divided into mini DAOs accompanied with full character IP rights</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
